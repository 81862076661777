import { Notify } from 'quasar';
import { TShowToast } from '@/types/toasts';

export const showToast: TShowToast = (message, type = 'info', spinner = false) => {
  Notify.create({
    message,
    type,
    spinner,
  })
};
